import { Component, ElementRef, ViewContainerRef, ChangeDetectionStrategy, QueryList, Renderer2, Injector, ValueProvider, ContentChild } from '@angular/core';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, setValue } from '@syncfusion/ej2-angular-base';
import { ChipList } from '@syncfusion/ej2-buttons';

import { ChipsDirective } from './chips.directive';

export const /** @type {?} */ inputs: string[] = ['avatarIconCss','avatarText','chips','cssClass','enableDelete','enablePersistence','enableRtl','enabled','leadingIconCss','leadingIconUrl','locale','selectedChips','selection','text','trailingIconCss','trailingIconUrl'];
export const /** @type {?} */ outputs: string[] = ['beforeClick','click','created','delete','deleted'];
export const /** @type {?} */ twoWays: string[] = [''];
/**
 * Represents the Essential JS 2 Angular ChipList Component.
 * ```html
 * <ejs-chiplist></ejs-chiplist>
 * ```
 */
@ComponentMixins([ComponentBase])
export class ChipListComponent extends ChipList implements IComponentBase {
public context : any;
public tagObjects: any;
	beforeClick: any;
	click: any;
	created: any;
	delete: any;
public deleted: any;
public childChips: QueryList<ChipsDirective>;
public tags: string[] = ['chips'];
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.context  = new ComponentBase();
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.context.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.context.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.context.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        this.tagObjects[0].instance = this.childChips;
        this.context.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-chiplist',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {
        childChips: new ContentChild(ChipsDirective)
    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
}

function ChipListComponent_tsickle_Closure_declarations() {
/** @type {?} */
ChipListComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ChipListComponent.ctorParameters;
/** @type {?} */
ChipListComponent.prototype.context;
/** @type {?} */
ChipListComponent.prototype.tagObjects;
/** @type {?} */
ChipListComponent.prototype.beforeClick;
/** @type {?} */
ChipListComponent.prototype.click;
/** @type {?} */
ChipListComponent.prototype.created;
/** @type {?} */
ChipListComponent.prototype.delete;
/** @type {?} */
ChipListComponent.prototype.deleted;
/** @type {?} */
ChipListComponent.prototype.childChips;
/** @type {?} */
ChipListComponent.prototype.tags;
/** @type {?} */
ChipListComponent.prototype.registerEvents;
/** @type {?} */
ChipListComponent.prototype.addTwoWay;
/** @type {?} */
ChipListComponent.prototype.ngEle;
/** @type {?} */
ChipListComponent.prototype.srenderer;
/** @type {?} */
ChipListComponent.prototype.viewContainerRef;
/** @type {?} */
ChipListComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

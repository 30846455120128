import { TreeView as BaseTreeView } from '@syncfusion/ej2-navigations';
import { isNullOrUndefined as isNOU, select, setValue, getValue, Draggable } from '@syncfusion/ej2-base';
import { KeyboardEvents, closest } from '@syncfusion/ej2-base';
import { DataManager, Query } from '@syncfusion/ej2-data';
import * as events from '../base/constant';
import * as CLS from '../base/classes';
import { read, Download, GetDetails, Delete } from '../common/operations';
import { createDialog } from '../pop-up/dialog';
import { updatePath, getPath, getDirectories } from '../common/utility';
import { createVirtualDragElement, dragStopHandler, dragStartHandler, draggingHandler, getDirectoryPath, getModule } from '../common/index';
import { copyFiles, cutFiles, removeActive, pasteHandler, getParentPath, readDropPath } from '../common/index';
import { hasEditAccess, createDeniedDialog, hasDownloadAccess, getAccessClass } from '../common/index';
/**
 * NavigationPane module
 */
export class NavigationPane {
    /**
     * Constructor for the TreeView module
     *
     * @param {IFileManager} parent - specifies the parent element.
     * @hidden
     */
    /* istanbul ignore next */
    constructor(parent) {
        this.removeNodes = [];
        this.moveNames = [];
        this.expandTree = false;
        this.isDrag = false;
        this.isPathDragged = false;
        this.isRenameParent = false;
        this.isRightClick = false;
        this.renameParent = null;
        // Specifies the previously selected nodes in the treeview control.
        this.previousSelected = null;
        // Specifies whether the nodeClicked event of the treeview control is triggered or not.
        this.isNodeClickCalled = false;
        // Specifies whether to restrict node selection in the treeview control.
        this.restrictSelecting = false;
        this.parent = parent;
        this.addEventListener();
        this.keyConfigs = {
            altEnter: 'alt+enter',
            esc: 'escape',
            del: 'delete',
            ctrlX: this.parent.isMac ? 'cmd+x' : 'ctrl+x',
            ctrlC: this.parent.isMac ? 'cmd+c' : 'ctrl+c',
            ctrlV: this.parent.isMac ? 'cmd+v' : 'ctrl+v',
            ctrlShiftN: 'ctrl+shift+n',
            shiftF10: 'shift+F10',
            f2: 'f2'
        };
    }
    onInit() {
        if (!isNOU(this.treeObj)) {
            return;
        }
        // eslint-disable-next-line
        const rootData = getValue(this.parent.pathId[0], this.parent.feParent);
        setValue('_fm_icon', 'e-fe-folder', rootData);
        // eslint-disable-next-line
        const attr = {};
        const id = getValue('id', rootData);
        if (!isNOU(id)) {
            setValue('data-id', id, attr);
        }
        if (!hasEditAccess(rootData)) {
            setValue('class', getAccessClass(rootData), attr);
        }
        if (!isNOU(attr)) {
            setValue('_fm_htmlAttr', attr, rootData);
        }
        this.treeObj = new BaseTreeView({
            fields: {
                dataSource: [rootData], id: '_fm_id', parentID: '_fm_pId', expanded: '_fm_expanded', selected: '_fm_selected', text: 'name',
                hasChildren: 'hasChild', iconCss: '_fm_icon', htmlAttributes: '_fm_htmlAttr', tooltip: 'name'
            },
            enableHtmlSanitizer: this.parent.enableHtmlSanitizer,
            sortOrder: this.parent.navigationPaneSettings.sortOrder,
            nodeSelecting: this.onNodeSelecting.bind(this),
            nodeSelected: this.onNodeSelected.bind(this),
            nodeExpanding: this.onNodeExpand.bind(this),
            nodeClicked: this.onNodeClicked.bind(this),
            allowEditing: true,
            nodeEditing: this.onNodeEditing.bind(this),
            drawNode: this.onDrowNode.bind(this),
            enableRtl: this.parent.enableRtl,
            dataBound: this.addDragDrop.bind(this)
        });
        this.treeObj.isStringTemplate = true;
        this.treeObj.appendTo('#' + this.parent.element.id + CLS.TREE_ID);
        this.wireEvents();
    }
    addDragDrop() {
        if (!this.parent.isMobile && this.treeObj) {
            if (this.parent.allowDragAndDrop) {
                if (this.dragObj) {
                    this.dragObj.destroy();
                }
                this.dragObj = new Draggable(this.treeObj.element, {
                    cursorAt: this.parent.dragCursorPosition,
                    dragTarget: '.' + CLS.FULLROW,
                    distance: 5,
                    dragArea: this.parent.element,
                    drag: draggingHandler.bind(this, this.parent),
                    dragStart: (args) => {
                        dragStartHandler(this.parent, args, this.dragObj);
                    },
                    dragStop: dragStopHandler.bind(this, this.parent),
                    enableTailMode: true,
                    enableAutoScroll: true,
                    helper: this.dragHelper.bind(this)
                });
            }
            else if (!this.parent.allowDragAndDrop && this.dragObj) {
                this.dragObj.destroy();
            }
        }
    }
    dragHelper(args) {
        const dragTarget = args.sender.target;
        if (!dragTarget.classList.contains(CLS.FULLROW)) {
            return null;
        }
        const dragLi = closest(dragTarget, 'li');
        this.parent.dragPath = '';
        this.parent.dragData = [];
        this.parent.activeElements = [];
        this.parent.activeElements = [dragLi];
        this.parent.dragNodes = [];
        getModule(this.parent, dragLi);
        // eslint-disable-next-line
        this.parent.dragData = this.getTreeData(dragLi);
        this.parent.dragPath = this.getDragPath(dragLi, this.parent.dragData[0].name);
        this.parent.dragNodes.push(this.parent.dragData[0].name);
        createVirtualDragElement(this.parent);
        return this.parent.virtualDragElement;
    }
    getDragPath(dragLi, text) {
        const path = this.getDropPath(dragLi, text);
        return getParentPath(path);
    }
    getDropPath(node, text) {
        const id = node.getAttribute('data-id');
        const newText = this.parent.hasId ? id : text;
        return getPath(node, newText, this.parent.hasId);
    }
    onDrowNode(args) {
        const eventArgs = {
            element: args.node,
            fileDetails: args.nodeData,
            module: 'NavigationPane'
        };
        this.parent.trigger('fileLoad', eventArgs);
    }
    // eslint-disable-next-line
    addChild(files, target, prevent) {
        // eslint-disable-next-line
        const directories = getDirectories(files);
        if (directories.length > 0) {
            let length = 0;
            // eslint-disable-next-line
            const folders = directories;
            while (length < directories.length) {
                // eslint-disable-next-line
                folders[length]._fm_icon = 'e-fe-folder';
                // eslint-disable-next-line
                const attr = {};
                const id = getValue('id', folders[length]);
                if (!isNOU(id)) {
                    setValue('data-id', id, attr);
                }
                if (!hasEditAccess(folders[length])) {
                    setValue('class', getAccessClass(folders[length]), attr);
                }
                if (!isNOU(attr)) {
                    setValue('_fm_htmlAttr', attr, folders[length]);
                }
                length++;
            }
            // eslint-disable-next-line
            this.treeObj.addNodes(directories, target, null, prevent);
        }
    }
    // Node Selecting event handler
    onNodeSelecting(args) {
        if (!args.isInteracted && !this.isRightClick && !this.isPathDragged && !this.isRenameParent || this.restrictSelecting) {
            this.restrictSelecting = false;
            this.isNodeClickCalled = false;
            return;
        }
        if (!this.renameParent) {
            this.parent.activeModule = 'navigationpane';
            // eslint-disable-next-line
            const nodeData = this.getTreeData(getValue('id', args.nodeData));
            const eventArgs = { cancel: false, fileDetails: nodeData[0], module: 'NavigationPane' };
            this.parent.trigger('fileOpen', eventArgs);
            args.cancel = eventArgs.cancel;
            if (args.cancel) {
                this.restrictSelecting = this.isNodeClickCalled ? this.previousSelected[0] !== args.node.getAttribute('data-uid') : false;
                this.treeObj.selectedNodes = this.isNodeClickCalled ? this.previousSelected : this.treeObj.selectedNodes;
                this.previousSelected = this.treeObj.selectedNodes;
                if (!isNOU(this.parent) && !isNOU(this.parent.contextmenuModule)) {
                    this.parent.contextmenuModule.contextMenu.enableItems(['Open'], true);
                }
            }
        }
    }
    // Opens the folder while clicking open context menu item in the treeview.
    openFileOnContextMenuClick(node) {
        const data = this.treeObj.getTreeData(node);
        // eslint-disable-next-line
        this.parent.selectedItems = [];
        this.parent.itemData = data;
        this.activeNode = node;
        this.parent.activeModule = 'navigationpane';
        updatePath(node, this.parent.itemData[0], this.parent);
        read(this.parent, this.isPathDragged ? events.pasteEnd : events.pathChanged, this.parent.path);
        this.parent.visitedItem = node;
        this.isPathDragged = this.isRenameParent = this.isRightClick = false;
        this.treeObj.selectedNodes = [node.getAttribute('data-uid')];
    }
    onNodeSelected(args) {
        if (this.parent.breadcrumbbarModule && this.parent.breadcrumbbarModule.searchObj && !this.renameParent) {
            this.parent.breadcrumbbarModule.searchObj.element.value = '';
            this.parent.isFiltered = false;
        }
        this.parent.searchedItems = [];
        if (!args.isInteracted && !this.isRightClick && !this.isPathDragged && !this.isRenameParent) {
            return;
        }
        this.activeNode = args.node;
        this.parent.activeModule = 'navigationpane';
        // eslint-disable-next-line
        const nodeData = this.getTreeData(getValue('id', args.nodeData));
        this.parent.selectedItems = [];
        this.parent.itemData = nodeData;
        const previousPath = this.parent.path;
        updatePath(args.node, this.parent.itemData[0], this.parent);
        if (previousPath !== this.parent.path) {
            this.expandNodeTarget = null;
            if (args.node.querySelector('.' + CLS.ICONS) && args.node.querySelector('.' + CLS.LIST_ITEM) === null) {
                this.expandNodeTarget = 'add';
            }
            read(this.parent, this.isPathDragged ? events.pasteEnd : events.pathChanged, this.parent.path);
            this.parent.visitedItem = args.node;
        }
        this.isPathDragged = this.isRenameParent = this.isRightClick = false;
    }
    /* istanbul ignore next */
    // eslint-disable-next-line
    onPathDrag(args) {
        this.isPathDragged = true;
        this.selectResultNode(args[0]);
    }
    /* istanbul ignore next */
    onNodeExpand(args) {
        if (!args.isInteracted && !this.isDrag) {
            return;
        }
        if (args.node.querySelector('.' + CLS.LIST_ITEM) === null) {
            const text = getValue('text', args.nodeData);
            const id = args.node.getAttribute('data-id');
            const isId = isNOU(id) ? false : true;
            const newText = isNOU(id) ? text : id;
            const path = getPath(args.node, newText, isId);
            this.expandNodeTarget = args.node.getAttribute('data-uid');
            this.parent.expandedId = this.expandNodeTarget;
            this.parent.itemData = this.getTreeData(getValue('id', args.nodeData));
            read(this.parent, events.nodeExpand, path);
        }
    }
    /* istanbul ignore next */
    onNodeExpanded(args) {
        this.addChild(args.files, this.expandNodeTarget, false);
        this.parent.expandedId = null;
    }
    onNodeClicked(args) {
        this.parent.activeModule = 'navigationpane';
        this.previousSelected = this.treeObj.selectedNodes;
        this.activeNode = args.node;
        if ((args.event.which === 3) && (args.node.getAttribute('data-uid') !== this.treeObj.selectedNodes[0])) {
            this.isRightClick = true;
            this.isNodeClickCalled = true;
            this.treeObj.selectedNodes = [args.node.getAttribute('data-uid')];
        }
        else if (args.node.getAttribute('data-uid') === this.treeObj.selectedNodes[0] && this.parent.selectedItems.length !== 0) {
            this.parent.setProperties({ selectedItems: [] }, true);
            const layout = (this.parent.view === 'LargeIcons') ? 'largeiconsview' : 'detailsview';
            this.parent.notify(events.modelChanged, { module: layout, newProp: { selectedItems: [] } });
        }
    }
    /* istanbul ignore next */
    onNodeEditing(args) {
        if (!isNOU(args.innerHtml)) {
            args.cancel = true;
        }
    }
    onPathChanged(args) {
        this.parent.isCut = false;
        // eslint-disable-next-line
        const currFiles = getValue(this.parent.pathId[this.parent.pathId.length - 1], this.parent.feFiles);
        if (this.expandNodeTarget === 'add') {
            const sNode = select('[data-uid="' + this.treeObj.selectedNodes[0] + '"]', this.treeObj.element);
            const ul = select('.' + CLS.LIST_PARENT, sNode);
            if (isNOU(ul)) {
                this.addChild(args.files, this.treeObj.selectedNodes[0], !this.expandTree);
            }
            this.expandNodeTarget = '';
        }
        this.expandTree = false;
        if (isNOU(currFiles)) {
            setValue(this.parent.pathId[this.parent.pathId.length - 1], args.files, this.parent.feFiles);
        }
    }
    updateTree(args) {
        if (this.treeObj) {
            const id = this.treeObj.selectedNodes[0];
            this.updateTreeNode(args, id);
        }
    }
    updateTreeNode(args, id) {
        const toExpand = this.treeObj.expandedNodes.indexOf(id) === -1 ? false : true;
        this.removeChildNodes(id);
        this.addChild(args.files, id, !toExpand);
    }
    removeChildNodes(id) {
        const sNode = select('[data-uid="' + id + '"]', this.treeObj.element);
        const parent = select('.' + CLS.LIST_PARENT, sNode);
        const childs = parent ? Array.prototype.slice.call(parent.children) : null;
        if (childs) {
            this.treeObj.removeNodes(childs);
        }
    }
    onOpenEnd(args) {
        const sleId = this.parent.pathId[this.parent.pathId.length - 1];
        this.treeObj.expandAll(this.treeObj.selectedNodes);
        this.treeObj.selectedNodes = [sleId];
        this.expandNodeTarget = 'add';
        this.onPathChanged(args);
    }
    onOpenInit(args) {
        if (this.parent.activeModule === 'navigationpane') {
            if (args.target.querySelector('.' + CLS.ICONS)) {
                this.treeObj.expandAll(this.treeObj.selectedNodes);
            }
        }
    }
    onInitialEnd(args) {
        this.onInit();
        this.addChild(args.files, getValue('_fm_id', args.cwd), false);
    }
    onFinalizeEnd(args) {
        this.onInit();
        const id = getValue('_fm_id', args.cwd);
        this.removeChildNodes(id);
        this.addChild(args.files, id, false);
        this.treeObj.selectedNodes = [this.parent.pathId[this.parent.pathId.length - 1]];
    }
    onCreateEnd(args) {
        this.updateTree(args);
    }
    onSelectedData() {
        if (this.parent.activeModule === 'navigationpane') {
            this.updateItemData();
        }
    }
    onDeleteInit() {
        if (this.parent.activeModule === 'navigationpane') {
            this.updateActionData();
            const name = getValue('name', this.parent.itemData[0]);
            Delete(this.parent, [name], this.parent.path, 'delete');
        }
    }
    /* istanbul ignore next */
    onDeleteEnd(args) {
        if (this.parent.activeModule === 'navigationpane') {
            const selectedNode = this.treeObj.selectedNodes[0];
            const selcetedEle = select('[data-uid="' + selectedNode + '"]', this.treeObj.element);
            const selectedNodeEle = closest(selcetedEle, '.' + CLS.LIST_PARENT).parentElement;
            this.treeObj.selectedNodes = [selectedNodeEle.getAttribute('data-uid')];
            this.treeObj.dataBind();
        }
        this.updateTree(args);
    }
    onRefreshEnd(args) {
        this.updateTree(args);
    }
    onRenameInit() {
        if (this.parent.activeModule === 'navigationpane') {
            this.updateRenameData();
        }
    }
    /* istanbul ignore next */
    onRenameEndParent(args) {
        const id = this.renameParent ? this.renameParent : this.parent.pathId[this.parent.pathId.length - 1];
        this.expandTree = this.treeObj.expandedNodes.indexOf(this.treeObj.selectedNodes[0]) !== -1;
        this.updateTreeNode(args, id);
        this.parent.expandedId = null;
        if (this.renameParent) {
            this.renameParent = null;
        }
        else {
            // eslint-disable-next-line
            let resultData = [];
            if (this.parent.hasId) {
                resultData = new DataManager(this.treeObj.getTreeData()).
                    executeLocal(new Query().where('id', 'equal', this.parent.renamedId, false));
            }
            else {
                // eslint-disable-next-line
                const nData = new DataManager(this.treeObj.getTreeData()).
                    executeLocal(new Query().where(this.treeObj.fields.text, 'equal', this.parent.renameText, false));
                if (nData.length > 0) {
                    resultData = new DataManager(nData).
                        executeLocal(new Query().where('_fm_pId', 'equal', id, false));
                }
            }
            if (resultData.length > 0) {
                this.isRenameParent = true;
                const id = getValue(this.treeObj.fields.id, resultData[0]);
                this.treeObj.selectedNodes = [id];
                this.treeObj.dataBind();
            }
        }
    }
    /* istanbul ignore next */
    onRenameEnd(args) {
        if (this.parent.breadcrumbbarModule.searchObj.element.value === '' && !this.parent.isFiltered) {
            this.updateTree(args);
        }
        else {
            // eslint-disable-next-line
            const data = this.treeObj.getTreeData();
            // eslint-disable-next-line
            let resultData = [];
            if (this.parent.hasId) {
                resultData = new DataManager(data).
                    executeLocal(new Query().where('id', 'equal', this.parent.renamedId, false));
            }
            else {
                // eslint-disable-next-line
                const nData = new DataManager(data).
                    executeLocal(new Query().where(this.treeObj.fields.text, 'equal', this.parent.currentItemText, false));
                if (nData.length > 0) {
                    resultData = new DataManager(nData).
                        executeLocal(new Query().where('filterPath', 'equal', this.parent.filterPath, false));
                }
            }
            if (resultData.length > 0) {
                this.renameParent = getValue(this.treeObj.fields.parentID, resultData[0]);
                this.parent.expandedId = this.renameParent;
                this.parent.itemData = this.getTreeData(this.renameParent);
                read(this.parent, events.renameEndParent, this.parent.filterPath.replace(/\\/g, '/'));
            }
        }
    }
    onPropertyChanged(e) {
        if (e.module !== this.getModuleName() && e.module !== 'common') {
            /* istanbul ignore next */
            return;
        }
        for (const prop of Object.keys(e.newProp)) {
            switch (prop) {
                case 'allowDragAndDrop':
                    this.addDragDrop();
                    break;
                case 'navigationPaneSettings':
                    read(this.parent, events.finalizeEnd, '/');
                    if (e.oldProp.navigationPaneSettings.sortOrder !== e.newProp.navigationPaneSettings.sortOrder) {
                        this.treeObj.sortOrder = e.newProp.navigationPaneSettings.sortOrder;
                    }
                    break;
            }
        }
    }
    /* istanbul ignore next */
    onDownLoadInit() {
        this.doDownload();
    }
    onSelectionChanged(e) {
        this.treeObj.selectedNodes = [e.selectedNode];
    }
    onClearPathInit(e) {
        this.removeChildNodes(e.selectedNode);
    }
    onDragEnd(args) {
        let moveNames = [];
        if (this.parent.isPasteError || this.parent.isSearchDrag) {
            moveNames = this.getMoveNames(args.files, this.parent.isSearchDrag, this.parent.dragPath);
        }
        else {
            moveNames = this.moveNames;
        }
        this.treeObj.removeNodes(moveNames);
    }
    // eslint-disable-next-line
    getMoveNames(files, flag, path) {
        const moveNames = [];
        for (let i = 0; i < files.length; i++) {
            if (!files[i].isFile) {
                if (!this.parent.hasId) {
                    let name = (files[i].previousName);
                    if (flag) {
                        path = path + files[i].previousName;
                        const index = path.lastIndexOf('/');
                        name = path.substring(index + 1);
                        path = path.substring(0, index + 1);
                    }
                    // eslint-disable-next-line
                    const resultData = new DataManager(this.treeObj.getTreeData()).
                        executeLocal(new Query().where(this.treeObj.fields.text, 'equal', name, false));
                    for (let j = 0; j < resultData.length; j++) {
                        let fPath = getValue('filterPath', resultData[j]);
                        fPath = fPath.replace(/\\/g, '/');
                        if (fPath === path) {
                            moveNames.push(getValue(this.treeObj.fields.id, resultData[j]));
                            break;
                        }
                    }
                }
            }
        }
        return moveNames;
    }
    onCutEnd(args) {
        let moveNames = [];
        if (this.parent.isPasteError || this.parent.isSearchCut) {
            this.moveNames = this.getMoveNames(args.files, this.parent.isSearchCut, this.parent.targetPath);
        }
        else {
            moveNames = this.moveNames;
        }
        this.treeObj.removeNodes(moveNames);
    }
    /* istanbul ignore next */
    // eslint-disable-next-line
    selectResultNode(resultObj) {
        if (!this.parent.hasId) {
            const path = getValue('filterPath', resultObj);
            const itemname = getValue('name', resultObj);
            // eslint-disable-next-line
            const data = new DataManager(this.treeObj.getTreeData()).
                executeLocal(new Query().where(this.treeObj.fields.text, 'equal', itemname, false));
            if (data.length > 0) {
                // eslint-disable-next-line
                const resultData = new DataManager(data).
                    executeLocal(new Query().where('filterPath', 'equal', path, false));
                if (resultData.length > 0) {
                    const id = getValue(this.treeObj.fields.id, resultData[0]);
                    this.treeObj.selectedNodes = [id];
                    this.treeObj.dataBind();
                }
            }
        }
        else {
            this.treeObj.selectedNodes = [getValue('_fm_id', resultObj)];
            this.treeObj.dataBind();
        }
    }
    onDropPath(args) {
        this.onpasteEnd(args);
        this.selectResultNode(this.parent.dropData);
        this.parent.isDropEnd = !this.parent.isPasteError;
    }
    onpasteEnd(args) {
        // eslint-disable-next-line
        let resultData = [];
        if (this.parent.hasId) {
            resultData = new DataManager(this.treeObj.getTreeData()).
                executeLocal(new Query().where('id', 'equal', getValue('id', args.cwd), false));
        }
        else {
            // eslint-disable-next-line
            const nData = new DataManager(this.treeObj.getTreeData()).
                executeLocal(new Query().where(this.treeObj.fields.text, 'equal', getValue('name', args.cwd), false));
            if (nData.length > 0) {
                resultData = new DataManager(nData).
                    executeLocal(new Query().where('filterPath', 'equal', getValue('filterPath', args.cwd), false));
            }
        }
        if (resultData.length > 0) {
            const id = getValue(this.treeObj.fields.id, resultData[0]);
            const toExpand = this.treeObj.expandedNodes.indexOf(id) === -1;
            this.removeChildNodes(id);
            this.addChild(args.files, id, toExpand);
        }
        this.parent.expandedId = null;
        this.onPathChanged(args);
        if (this.parent.isDragDrop) {
            this.checkDropPath(args);
        }
    }
    /* istanbul ignore next */
    checkDropPath(args) {
        if (this.parent.hasId) {
            this.parent.isDropEnd = !this.parent.isPasteError;
            readDropPath(this.parent);
            return;
        }
        if ((this.parent.dropPath.indexOf(getDirectoryPath(this.parent, args)) === -1)) {
            this.parent.isDropEnd = false;
            readDropPath(this.parent);
        }
        else {
            this.parent.isDropEnd = !this.parent.isPasteError;
        }
    }
    onpasteInit() {
        if (this.parent.activeModule === this.getModuleName()) {
            this.updateItemData();
        }
        this.moveNames = [];
        // eslint-disable-next-line
        const obj = this.parent.isDragDrop ? this.parent.dragData : this.parent.actionRecords;
        for (let i = 0; i < obj.length; i++) {
            if (getValue('isFile', obj[i]) === false) {
                this.moveNames.push(getValue('_fm_id', obj[i]));
            }
        }
    }
    oncutCopyInit() {
        if (this.parent.activeModule === this.getModuleName()) {
            this.parent.activeRecords = this.getTreeData(this.treeObj.selectedNodes[0]);
            this.parent.activeElements = [this.activeNode];
        }
    }
    addEventListener() {
        this.parent.on(events.modelChanged, this.onPropertyChanged, this);
        this.parent.on(events.downloadInit, this.onDownLoadInit, this);
        this.parent.on(events.initialEnd, this.onInitialEnd, this);
        this.parent.on(events.finalizeEnd, this.onFinalizeEnd, this);
        this.parent.on(events.pathChanged, this.onPathChanged, this);
        this.parent.on(events.pasteEnd, this.onpasteEnd, this);
        this.parent.on(events.cutEnd, this.onCutEnd, this);
        this.parent.on(events.pasteInit, this.onpasteInit, this);
        this.parent.on(events.nodeExpand, this.onNodeExpanded, this);
        this.parent.on(events.createEnd, this.onCreateEnd, this);
        this.parent.on(events.selectedData, this.onSelectedData, this);
        this.parent.on(events.deleteInit, this.onDeleteInit, this);
        this.parent.on(events.deleteEnd, this.onDeleteEnd, this);
        this.parent.on(events.refreshEnd, this.onRefreshEnd, this);
        this.parent.on(events.updateTreeSelection, this.onSelectionChanged, this);
        this.parent.on(events.openInit, this.onOpenInit, this);
        this.parent.on(events.openEnd, this.onOpenEnd, this);
        this.parent.on(events.destroy, this.destroy, this);
        this.parent.on(events.renameInit, this.onRenameInit, this);
        this.parent.on(events.renameEnd, this.onRenameEnd, this);
        this.parent.on(events.renameEndParent, this.onRenameEndParent, this);
        this.parent.on(events.clearPathInit, this.onClearPathInit, this);
        this.parent.on(events.cutCopyInit, this.oncutCopyInit, this);
        this.parent.on(events.dropInit, this.onDropInit, this);
        this.parent.on(events.menuItemData, this.onMenuItemData, this);
        this.parent.on(events.dragEnd, this.onDragEnd, this);
        this.parent.on(events.dragging, this.onDragging, this);
        this.parent.on(events.dropPath, this.onDropPath, this);
        this.parent.on(events.detailsInit, this.onDetailsInit, this);
        this.parent.on(events.pathDrag, this.onPathDrag, this);
    }
    removeEventListener() {
        this.parent.off(events.initialEnd, this.onInitialEnd);
        this.parent.off(events.downloadInit, this.onDownLoadInit);
        this.parent.off(events.finalizeEnd, this.onFinalizeEnd);
        this.parent.off(events.selectedData, this.onSelectedData);
        this.parent.off(events.modelChanged, this.onPropertyChanged);
        this.parent.off(events.pathChanged, this.onPathChanged);
        this.parent.off(events.pasteEnd, this.onpasteEnd);
        this.parent.off(events.cutEnd, this.onCutEnd);
        this.parent.off(events.pasteInit, this.onpasteInit);
        this.parent.off(events.updateTreeSelection, this.onSelectionChanged);
        this.parent.off(events.nodeExpand, this.onNodeExpanded);
        this.parent.off(events.createEnd, this.onCreateEnd);
        this.parent.off(events.refreshEnd, this.onRefreshEnd);
        this.parent.off(events.openInit, this.onOpenInit);
        this.parent.off(events.openEnd, this.onOpenEnd);
        this.parent.off(events.destroy, this.destroy);
        this.parent.off(events.renameInit, this.onRenameInit);
        this.parent.off(events.renameEnd, this.onRenameEnd);
        this.parent.off(events.renameEndParent, this.onRenameEndParent);
        this.parent.off(events.clearPathInit, this.onClearPathInit);
        this.parent.off(events.deleteInit, this.onDeleteInit);
        this.parent.off(events.deleteEnd, this.onDeleteEnd);
        this.parent.off(events.cutCopyInit, this.oncutCopyInit);
        this.parent.off(events.dropInit, this.onDropInit);
        this.parent.off(events.dragEnd, this.onDragEnd);
        this.parent.off(events.dragging, this.onDragging);
        this.parent.off(events.dropPath, this.onDropPath);
        this.parent.off(events.detailsInit, this.onDetailsInit);
        this.parent.off(events.menuItemData, this.onMenuItemData);
        this.parent.off(events.pathDrag, this.onPathDrag);
    }
    /* istanbul ignore next */
    onDetailsInit() {
        if (this.parent.activeModule === this.getModuleName()) {
            // eslint-disable-next-line
            const dataobj = this.getTreeData(this.treeObj.selectedNodes[0]);
            this.parent.itemData = dataobj;
        }
    }
    // eslint-disable-next-line
    onMenuItemData(args) {
        if (this.parent.activeModule === this.getModuleName()) {
            const liEle = closest(args.target, 'li');
            this.parent.itemData = this.getTreeData(liEle.getAttribute('data-uid'));
        }
    }
    /* istanbul ignore next */
    onDragging(args) {
        const ele = closest(args.target, 'li');
        if (ele.classList.contains('e-node-collapsed')) {
            this.isDrag = true;
            const level = parseInt(ele.getAttribute('aria-level'), 10);
            this.treeObj.expandAll([ele.getAttribute('data-uid')], level + 1);
            this.isDrag = false;
        }
    }
    onDropInit(args) {
        if (this.parent.targetModule === this.getModuleName()) {
            const dropLi = closest(args.target, 'li');
            this.parent.dropData = this.getTreeData(dropLi)[0];
            this.parent.dropPath = this.getDropPath(dropLi, getValue('name', this.parent.dropData));
        }
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} - returns the module name.
     * @private
     */
    getModuleName() {
        return 'navigationpane';
    }
    destroy() {
        if (this.parent.isDestroyed) {
            return;
        }
        this.removeEventListener();
        if (this.treeObj) {
            this.unWireEvents();
            this.treeObj.destroy();
        }
    }
    wireEvents() {
        this.keyboardModule = new KeyboardEvents(this.treeObj.element, {
            keyAction: this.keyDown.bind(this),
            keyConfigs: this.keyConfigs,
            eventName: 'keydown'
        });
    }
    unWireEvents() {
        this.keyboardModule.destroy();
    }
    /* istanbul ignore next */
    keyDown(e) {
        const action = e.action;
        switch (action) {
            case 'altEnter':
                this.parent.notify(events.detailsInit, {});
                GetDetails(this.parent, [], this.parent.path, 'details');
                break;
            case 'esc':
                removeActive(this.parent);
                break;
            case 'del':
                if (this.parent.pathId[0] !== this.activeNode.getAttribute('data-uid')) {
                    this.updateItemData();
                    if (!hasEditAccess(this.parent.itemData[0])) {
                        createDeniedDialog(this.parent, this.parent.itemData[0], events.permissionEdit);
                    }
                    else {
                        this.removeNodes = [];
                        createDialog(this.parent, 'Delete');
                    }
                }
                break;
            case 'ctrlC':
                copyFiles(this.parent);
                break;
            case 'ctrlV':
                this.parent.folderPath = '';
                pasteHandler(this.parent);
                break;
            case 'ctrlX':
                cutFiles(this.parent);
                break;
            case 'shiftF10':
                this.updateItemData();
                if (!hasDownloadAccess(this.parent.itemData[0])) {
                    createDeniedDialog(this.parent, this.parent.itemData[0], events.permissionDownload);
                    return;
                }
                if (this.parent.selectedItems.length !== 0) {
                    this.doDownload();
                }
                break;
            case 'f2':
                if (this.parent.selectedItems.length === 0) {
                    // eslint-disable-next-line
                    const data = this.getTreeData(this.treeObj.selectedNodes[0])[0];
                    if (!hasEditAccess(data)) {
                        createDeniedDialog(this.parent, data, events.permissionEdit);
                    }
                    else {
                        this.updateRenameData();
                        createDialog(this.parent, 'Rename');
                    }
                }
                break;
        }
    }
    // eslint-disable-next-line
    getTreeData(args) {
        // eslint-disable-next-line
        const data = this.treeObj.getTreeData(args);
        for (let i = 0; i < data.length; i++) {
            if (isNOU(getValue('hasChild', data[i]))) {
                setValue('hasChild', false, data[i]);
            }
        }
        return data;
    }
    updateRenameData() {
        this.updateItemData();
        this.parent.currentItemText = getValue('name', this.parent.itemData[0]);
    }
    updateItemData() {
        // eslint-disable-next-line
        const data = this.getTreeData(this.treeObj.selectedNodes[0])[0];
        this.parent.itemData = [data];
        this.parent.isFile = false;
    }
    updateActionData() {
        this.updateItemData();
        const newPath = getParentPath(this.parent.path);
        this.parent.setProperties({ path: newPath }, true);
        this.parent.pathId.pop();
        this.parent.pathNames.pop();
    }
    /* istanbul ignore next */
    doDownload() {
        const newPath = getParentPath(this.parent.path);
        const itemId = this.treeObj.selectedNodes[0];
        const name = (itemId === this.parent.pathId[0]) ? '' : getValue('name', this.parent.itemData[0]);
        Download(this.parent, newPath, [name]);
    }
}

import { Component, ElementRef, ViewContainerRef, ChangeDetectionStrategy, QueryList, Renderer2, Injector, ValueProvider } from '@angular/core';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, setValue } from '@syncfusion/ej2-angular-base';
import { ContextMenu } from '@syncfusion/ej2-navigations';



export const /** @type {?} */ inputs: string[] = ['animationSettings','cssClass','enableHtmlSanitizer','enablePersistence','enableRtl','enableScrolling','fields','filter','hoverDelay','items','locale','showItemOnClick','target','template'];
export const /** @type {?} */ outputs: string[] = ['beforeClose','beforeItemRender','beforeOpen','created','onClose','onOpen','select'];
export const /** @type {?} */ twoWays: string[] = [''];
/**
 * Represents the EJ2 Angular ContextMenu Component.
 * ```html
 * <div id='target'>Right click / Touch hold to open the ContextMenu</div>
 * <ejs-contextmenu target='#target' [items]='menuItems'></ejs-contextmenu>
 * ```
 */
@ComponentMixins([ComponentBase])
export class ContextMenuComponent extends ContextMenu implements IComponentBase {
public context : any;
public tagObjects: any;
	beforeClose: any;
	beforeItemRender: any;
	beforeOpen: any;
	created: any;
	onClose: any;
	onOpen: any;
public select: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.context  = new ComponentBase();
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.context.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.context.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.context.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.context.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-contextmenu',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
}

function ContextMenuComponent_tsickle_Closure_declarations() {
/** @type {?} */
ContextMenuComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ContextMenuComponent.ctorParameters;
/** @type {?} */
ContextMenuComponent.prototype.context;
/** @type {?} */
ContextMenuComponent.prototype.tagObjects;
/** @type {?} */
ContextMenuComponent.prototype.beforeClose;
/** @type {?} */
ContextMenuComponent.prototype.beforeItemRender;
/** @type {?} */
ContextMenuComponent.prototype.beforeOpen;
/** @type {?} */
ContextMenuComponent.prototype.created;
/** @type {?} */
ContextMenuComponent.prototype.onClose;
/** @type {?} */
ContextMenuComponent.prototype.onOpen;
/** @type {?} */
ContextMenuComponent.prototype.select;
/** @type {?} */
ContextMenuComponent.prototype.registerEvents;
/** @type {?} */
ContextMenuComponent.prototype.addTwoWay;
/** @type {?} */
ContextMenuComponent.prototype.ngEle;
/** @type {?} */
ContextMenuComponent.prototype.srenderer;
/** @type {?} */
ContextMenuComponent.prototype.viewContainerRef;
/** @type {?} */
ContextMenuComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuComponent } from './contextmenu.component';
import { ContextMenuModule } from './contextmenu.module';
/**
 * NgModule definition for the ContextMenu component with providers.
 */
export class ContextMenuAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, ContextMenuModule],
    exports: [
        ContextMenuModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ContextMenuAllModule_tsickle_Closure_declarations() {
/** @type {?} */
ContextMenuAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ContextMenuAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

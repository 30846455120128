import { Directive, ViewContainerRef, ContentChildren, ContentChild } from '@angular/core';
import { ComplexBase, ArrayBase, setValue } from '@syncfusion/ej2-angular-base';
import { Template } from '@syncfusion/ej2-angular-base';


let /** @type {?} */ input: string[] = ['cssClass', 'htmlAttributes', 'interval', 'template'];
let /** @type {?} */ outputs: string[] = [];
/**
 * `e-carousel-item` directive represent a item of the Angular Carousel.
 * It must be contained in a Carousel component(`ejs-carousel`).
 * ```html
 * <ejs-carousel>
 *   <e-carousel-items>
 *    <e-carousel-item template='#item1'></e-carousel-item>
 *    <e-carousel-item template='#item2'></e-carousel-item>
 *   </e-carousel-items>
 * </ejs-carousel>
 * ```
 */
export class CarouselItemDirective extends ComplexBase<CarouselItemDirective> {
public directivePropList: any;
/**
 * Accepts single/multiple classes (separated by a space) to be used for individual carousel item customization.
 * \@default null
 */
public cssClass: any;
/**
 * Accepts HTML attributes/custom attributes to add in individual carousel item.
 * \@default null
 */
public htmlAttributes: any;
/**
 * Accepts the interval duration in milliseconds for individual carousel item transition.
 * \@default null
 */
public interval: any;
/**
 * Accepts the template for individual carousel item.
 * \@default null
 */
@Template()
    public template: any;
/**
 * @param {?} viewContainerRef
 */
constructor(private viewContainerRef:ViewContainerRef) {
        super();
        setValue('currentInstance', this, this.viewContainerRef);
        this.registerEvents(outputs);
        this.directivePropList = input;
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'ejs-carousel>e-carousel-items>e-carousel-item',
    inputs: input,
    outputs: outputs,    
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ViewContainerRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'template': [{ type: ContentChild, args: ['template', ] },],
};
}

function CarouselItemDirective_tsickle_Closure_declarations() {
/** @type {?} */
CarouselItemDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
CarouselItemDirective.ctorParameters;
/** @type {?} */
CarouselItemDirective.propDecorators;
/** @type {?} */
CarouselItemDirective.prototype.directivePropList;
/**
 * Accepts single/multiple classes (separated by a space) to be used for individual carousel item customization.
 * \@default null
 * @type {?}
 */
CarouselItemDirective.prototype.cssClass;
/**
 * Accepts HTML attributes/custom attributes to add in individual carousel item.
 * \@default null
 * @type {?}
 */
CarouselItemDirective.prototype.htmlAttributes;
/**
 * Accepts the interval duration in milliseconds for individual carousel item transition.
 * \@default null
 * @type {?}
 */
CarouselItemDirective.prototype.interval;
/**
 * Accepts the template for individual carousel item.
 * \@default null
 * @type {?}
 */
CarouselItemDirective.prototype.template;
/** @type {?} */
CarouselItemDirective.prototype.viewContainerRef;
}

/**
 * CarouselItem Array Directive
 */
export class CarouselItemsDirective extends ArrayBase<CarouselItemsDirective> {
constructor() {
        super('items');
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'ejs-carousel>e-carousel-items',
    queries: {
        children: new ContentChildren(CarouselItemDirective)
    },
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function CarouselItemsDirective_tsickle_Closure_declarations() {
/** @type {?} */
CarouselItemsDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
CarouselItemsDirective.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbItemDirective, BreadcrumbItemsDirective } from './items.directive';
import { BreadcrumbComponent } from './breadcrumb.component';
/**
 * NgModule definition for the Breadcrumb component.
 */
export class BreadcrumbModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule],
    declarations: [
        BreadcrumbComponent,
        BreadcrumbItemDirective,
        BreadcrumbItemsDirective
    ],
    exports: [
        BreadcrumbComponent,
        BreadcrumbItemDirective,
        BreadcrumbItemsDirective
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function BreadcrumbModule_tsickle_Closure_declarations() {
/** @type {?} */
BreadcrumbModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
BreadcrumbModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

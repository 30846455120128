import { Directive, ViewContainerRef, ContentChildren } from '@angular/core';
import { ComplexBase, ArrayBase, setValue } from '@syncfusion/ej2-angular-base';



let /** @type {?} */ input: string[] = ['iconCss', 'id', 'items', 'separator', 'text', 'url'];
let /** @type {?} */ outputs: string[] = [];
export class MenuItemDirective extends ComplexBase<MenuItemDirective> {
public directivePropList: any;
/**
 * Defines class/multiple classes separated by a space for the menu Item that is used to include an icon. 
 * Menu Item can include font icon and sprite image.
 * \@default null
 */
public iconCss: any;
/**
 * Specifies the id for menu item.
 * \@default ''
 */
public id: any;
/**
 * Specifies the sub menu items that is the array of MenuItem model.
 * \@default []
 */
public items: any;
/**
 * Specifies separator between the menu items. Separator are either horizontal or vertical lines used to group menu items.
 * \@default false
 */
public separator: any;
/**
 * Specifies text for menu item.
 * \@default ''
 */
public text: any;
/**
 * Specifies url for menu item that creates the anchor link to navigate to the url provided.
 * \@default ''
 */
public url: any;
/**
 * @param {?} viewContainerRef
 */
constructor(private viewContainerRef:ViewContainerRef) {
        super();
        setValue('currentInstance', this, this.viewContainerRef);
        this.registerEvents(outputs);
        this.directivePropList = input;
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'ejs-menu>e-menu-items>e-menu-item>',
    inputs: input,
    outputs: outputs,    
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ViewContainerRef, },
];
}

function MenuItemDirective_tsickle_Closure_declarations() {
/** @type {?} */
MenuItemDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
MenuItemDirective.ctorParameters;
/** @type {?} */
MenuItemDirective.prototype.directivePropList;
/**
 * Defines class/multiple classes separated by a space for the menu Item that is used to include an icon. 
 * Menu Item can include font icon and sprite image.
 * \@default null
 * @type {?}
 */
MenuItemDirective.prototype.iconCss;
/**
 * Specifies the id for menu item.
 * \@default ''
 * @type {?}
 */
MenuItemDirective.prototype.id;
/**
 * Specifies the sub menu items that is the array of MenuItem model.
 * \@default []
 * @type {?}
 */
MenuItemDirective.prototype.items;
/**
 * Specifies separator between the menu items. Separator are either horizontal or vertical lines used to group menu items.
 * \@default false
 * @type {?}
 */
MenuItemDirective.prototype.separator;
/**
 * Specifies text for menu item.
 * \@default ''
 * @type {?}
 */
MenuItemDirective.prototype.text;
/**
 * Specifies url for menu item that creates the anchor link to navigate to the url provided.
 * \@default ''
 * @type {?}
 */
MenuItemDirective.prototype.url;
/** @type {?} */
MenuItemDirective.prototype.viewContainerRef;
}

/**
 * MenuItem Array Directive
 */
export class MenuItemsDirective extends ArrayBase<MenuItemsDirective> {
constructor() {
        super('items');
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'ejs-menu>e-menu-items',
    queries: {
        children: new ContentChildren(MenuItemDirective)
    },
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function MenuItemsDirective_tsickle_Closure_declarations() {
/** @type {?} */
MenuItemsDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
MenuItemsDirective.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchComponent } from './switch.component';
/**
 * NgModule definition for the Switch component.
 */
export class SwitchModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule],
    declarations: [
        SwitchComponent
    ],
    exports: [
        SwitchComponent
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function SwitchModule_tsickle_Closure_declarations() {
/** @type {?} */
SwitchModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
SwitchModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { Component, ElementRef, ViewContainerRef, ChangeDetectionStrategy, QueryList, Renderer2, Injector, ValueProvider, ContentChild } from '@angular/core';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, setValue } from '@syncfusion/ej2-angular-base';
import { Menu } from '@syncfusion/ej2-navigations';
import { Template } from '@syncfusion/ej2-angular-base';
import { MenuItemsDirective } from './items.directive';

export const /** @type {?} */ inputs: string[] = ['animationSettings','cssClass','enableHtmlSanitizer','enablePersistence','enableRtl','enableScrolling','fields','filter','hamburgerMode','hoverDelay','items','locale','orientation','showItemOnClick','target','template','title'];
export const /** @type {?} */ outputs: string[] = ['beforeClose','beforeItemRender','beforeOpen','created','onClose','onOpen','select'];
export const /** @type {?} */ twoWays: string[] = [''];
/**
 * Represents the EJ2 Angular Menu Component.
 * ```html
 * <ejs-menu [items]='menuItems'></ejs-menu>
 * ```
 */
@ComponentMixins([ComponentBase])
export class MenuComponent extends Menu implements IComponentBase {
public context : any;
public tagObjects: any;
	beforeClose: any;
	beforeItemRender: any;
	beforeOpen: any;
	created: any;
	onClose: any;
	onOpen: any;
public select: any;
public childItems: QueryList<MenuItemsDirective>;
public tags: string[] = ['items'];
/**
 * Specifies the template for Menu item.
 * \@default null
 */
@Template()
    public template: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.context  = new ComponentBase();
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.context.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.context.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.context.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        this.tagObjects[0].instance = this.childItems;
        this.context.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-menu',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {
        childItems: new ContentChild(MenuItemsDirective)
    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'template': [{ type: ContentChild, args: ['template', ] },],
};
}

function MenuComponent_tsickle_Closure_declarations() {
/** @type {?} */
MenuComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
MenuComponent.ctorParameters;
/** @type {?} */
MenuComponent.propDecorators;
/** @type {?} */
MenuComponent.prototype.context;
/** @type {?} */
MenuComponent.prototype.tagObjects;
/** @type {?} */
MenuComponent.prototype.beforeClose;
/** @type {?} */
MenuComponent.prototype.beforeItemRender;
/** @type {?} */
MenuComponent.prototype.beforeOpen;
/** @type {?} */
MenuComponent.prototype.created;
/** @type {?} */
MenuComponent.prototype.onClose;
/** @type {?} */
MenuComponent.prototype.onOpen;
/** @type {?} */
MenuComponent.prototype.select;
/** @type {?} */
MenuComponent.prototype.childItems;
/** @type {?} */
MenuComponent.prototype.tags;
/**
 * Specifies the template for Menu item.
 * \@default null
 * @type {?}
 */
MenuComponent.prototype.template;
/** @type {?} */
MenuComponent.prototype.registerEvents;
/** @type {?} */
MenuComponent.prototype.addTwoWay;
/** @type {?} */
MenuComponent.prototype.ngEle;
/** @type {?} */
MenuComponent.prototype.srenderer;
/** @type {?} */
MenuComponent.prototype.viewContainerRef;
/** @type {?} */
MenuComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

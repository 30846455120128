import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckBoxComponent } from './checkbox.component';
import { CheckBoxModule } from './checkbox.module';
/**
 * NgModule definition for the CheckBox component with providers.
 */
export class CheckBoxAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, CheckBoxModule],
    exports: [
        CheckBoxModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function CheckBoxAllModule_tsickle_Closure_declarations() {
/** @type {?} */
CheckBoxAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
CheckBoxAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { SidebarModule } from './sidebar.module';
/**
 * NgModule definition for the Sidebar component with providers.
 */
export class SidebarAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, SidebarModule],
    exports: [
        SidebarModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function SidebarAllModule_tsickle_Closure_declarations() {
/** @type {?} */
SidebarAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
SidebarAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionItemDirective, AccordionItemsDirective } from './items.directive';
import { AccordionComponent } from './accordion.component';
import { AccordionModule } from './accordion.module';
/**
 * NgModule definition for the Accordion component with providers.
 */
export class AccordionAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, AccordionModule],
    exports: [
        AccordionModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function AccordionAllModule_tsickle_Closure_declarations() {
/** @type {?} */
AccordionAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
AccordionAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { Directive, ViewContainerRef, ContentChildren } from '@angular/core';
import { ComplexBase, ArrayBase, setValue } from '@syncfusion/ej2-angular-base';



let /** @type {?} */ input: string[] = ['buttonModel', 'isFlat', 'type'];
let /** @type {?} */ outputs: string[] = ['click'];
/**
 * 'e-button' directive represent a button of angular dialog 
 * It must be contained in a Dialog component(`ej-dialog`). 
 * ```html
 * <ejs-dialog id='dialog' showCloseIcon=true> 
 *   <e-buttons>
 *    <e-dialogbutton [buttonModal]='okButton'></e-button>
 *    <e-dialogbutton [buttonModal]='cancelButton'></e-button>
 *   </e-buttons>
 * </ejs-dialog>
 * ```
 */
export class DialogButtonDirective extends ComplexBase<DialogButtonDirective> {
public directivePropList: any;
public click: any;
/**
 * Specify the type of the button. 
 * Possible values are Button, Submit and Reset.
 * \@default 'Button'
 * \@asptype string
 * \@blazortype string
 */
public type: any;
/**
 * Specifies the button component properties to render the dialog buttons.
 */
public buttonModel: any;
/**
 * Specifies the flat appearance of the dialog buttons
 * \@default true
 */
public isFlat: any;
/**
 * @param {?} viewContainerRef
 */
constructor(private viewContainerRef:ViewContainerRef) {
        super();
        setValue('currentInstance', this, this.viewContainerRef);
        this.registerEvents(outputs);
        this.directivePropList = input;
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'e-buttons>e-dialogbutton',
    inputs: input,
    outputs: outputs,    
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ViewContainerRef, },
];
}

function DialogButtonDirective_tsickle_Closure_declarations() {
/** @type {?} */
DialogButtonDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
DialogButtonDirective.ctorParameters;
/** @type {?} */
DialogButtonDirective.prototype.directivePropList;
/** @type {?} */
DialogButtonDirective.prototype.click;
/**
 * Specify the type of the button. 
 * Possible values are Button, Submit and Reset.
 * \@default 'Button'
 * \@asptype string
 * \@blazortype string
 * @type {?}
 */
DialogButtonDirective.prototype.type;
/**
 * Specifies the button component properties to render the dialog buttons.
 * @type {?}
 */
DialogButtonDirective.prototype.buttonModel;
/**
 * Specifies the flat appearance of the dialog buttons
 * \@default true
 * @type {?}
 */
DialogButtonDirective.prototype.isFlat;
/** @type {?} */
DialogButtonDirective.prototype.viewContainerRef;
}

/**
 * DialogButton Array Directive
 */
export class ButtonsDirective extends ArrayBase<ButtonsDirective> {
constructor() {
        super('buttons');
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'ejs-dialog>e-buttons',
    queries: {
        children: new ContentChildren(DialogButtonDirective)
    },
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ButtonsDirective_tsickle_Closure_declarations() {
/** @type {?} */
ButtonsDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ButtonsDirective.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipDirective, ChipsDirective } from './chips.directive';
import { ChipListComponent } from './chiplist.component';
/**
 * NgModule definition for the ChipList component.
 */
export class ChipListModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule],
    declarations: [
        ChipListComponent,
        ChipDirective,
        ChipsDirective
    ],
    exports: [
        ChipListComponent,
        ChipDirective,
        ChipsDirective
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ChipListModule_tsickle_Closure_declarations() {
/** @type {?} */
ChipListModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ChipListModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

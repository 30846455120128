import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselItemDirective, CarouselItemsDirective } from './items.directive';
import { CarouselComponent } from './carousel.component';
import { CarouselModule } from './carousel.module';
/**
 * NgModule definition for the Carousel component with providers.
 */
export class CarouselAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, CarouselModule],
    exports: [
        CarouselModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function CarouselAllModule_tsickle_Closure_declarations() {
/** @type {?} */
CarouselAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
CarouselAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

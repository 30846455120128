import { Component, ElementRef, ViewContainerRef, Renderer2, Injector, ChangeDetectionStrategy, QueryList, ValueProvider, ContentChild } from '@angular/core';
import { ComponentBase, ComponentMixins, IComponentBase, applyMixins, PropertyCollectionInfo, setValue } from '@syncfusion/ej2-angular-base';
import { Tooltip } from '@syncfusion/ej2-popups';
import { Template } from '@syncfusion/ej2-angular-base';


export const /** @type {?} */ inputs: string[] = ['animation','closeDelay','container','content','cssClass','enableHtmlParse','enableHtmlSanitizer','enablePersistence','enableRtl','height','isSticky','locale','mouseTrail','offsetX','offsetY','openDelay','opensOn','position','showTipPointer','target','tipPointerPosition','width','windowCollision'];
export const /** @type {?} */ outputs: string[] = ['afterClose','afterOpen','beforeClose','beforeCollision','beforeOpen','beforeRender','created','destroyed'];
export const /** @type {?} */ twoWays: string[] = [''];
/**
 * Represents the Angular Tooltip component that displays a piece of information about the target element on mouse hover.
 * ```html
 * <ejs-tooltip content='Tooltip content'>Show Tooltip</ejs-tooltip>
 * ```
 */
@ComponentMixins([ComponentBase])
export class TooltipComponent extends Tooltip implements IComponentBase {
public containerContext : any;
public tagObjects: any;
	afterClose: any;
	afterOpen: any;
	beforeClose: any;
	beforeCollision: any;
	beforeOpen: any;
	beforeRender: any;
	created: any;
public destroyed: any;
/**
 * It is used to display the content of Tooltip which can be both string and HTML Elements. 
 * Refer the documentation [here](https://ej2.syncfusion.com/documentation/tooltip/content/) 
 *  to know more about this property with demo.
 * 
 * {% codeBlock src="tooltip/content-api/index.ts" %}{% endcodeBlock %}
 * 
 * 
 */
@Template()
    public content: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.containerContext  = new ComponentBase();
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.containerContext.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.containerContext.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.containerContext.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.containerContext.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-tooltip',
    inputs: inputs,
    outputs: outputs,
    template: `<ng-content ></ng-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'content': [{ type: ContentChild, args: ['content', ] },],
};
}

function TooltipComponent_tsickle_Closure_declarations() {
/** @type {?} */
TooltipComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
TooltipComponent.ctorParameters;
/** @type {?} */
TooltipComponent.propDecorators;
/** @type {?} */
TooltipComponent.prototype.containerContext;
/** @type {?} */
TooltipComponent.prototype.tagObjects;
/** @type {?} */
TooltipComponent.prototype.afterClose;
/** @type {?} */
TooltipComponent.prototype.afterOpen;
/** @type {?} */
TooltipComponent.prototype.beforeClose;
/** @type {?} */
TooltipComponent.prototype.beforeCollision;
/** @type {?} */
TooltipComponent.prototype.beforeOpen;
/** @type {?} */
TooltipComponent.prototype.beforeRender;
/** @type {?} */
TooltipComponent.prototype.created;
/** @type {?} */
TooltipComponent.prototype.destroyed;
/**
 * It is used to display the content of Tooltip which can be both string and HTML Elements. 
 * Refer the documentation [here](https://ej2.syncfusion.com/documentation/tooltip/content/) 
 *  to know more about this property with demo.
 * 
 * {% codeBlock src="tooltip/content-api/index.ts" %}{% endcodeBlock %}
 * 
 * 
 * @type {?}
 */
TooltipComponent.prototype.content;
/** @type {?} */
TooltipComponent.prototype.registerEvents;
/** @type {?} */
TooltipComponent.prototype.addTwoWay;
/** @type {?} */
TooltipComponent.prototype.ngEle;
/** @type {?} */
TooltipComponent.prototype.srenderer;
/** @type {?} */
TooltipComponent.prototype.viewContainerRef;
/** @type {?} */
TooltipComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { Component, ElementRef, ViewContainerRef, Renderer2, Injector, ChangeDetectionStrategy, QueryList, ValueProvider, ContentChild } from '@angular/core';
import { ComponentBase, ComponentMixins, IComponentBase, applyMixins, PropertyCollectionInfo, setValue } from '@syncfusion/ej2-angular-base';
import { Toolbar } from '@syncfusion/ej2-navigations';

import { ItemsDirective } from './items.directive';

export const /** @type {?} */ inputs: string[] = ['allowKeyboard','cssClass','enableCollision','enableHtmlSanitizer','enablePersistence','enableRtl','height','items','locale','overflowMode','scrollStep','width'];
export const /** @type {?} */ outputs: string[] = ['beforeCreate','clicked','created','destroyed'];
export const /** @type {?} */ twoWays: string[] = [''];
/**
 * Represents the Angular Toolbar Component.
 * ```html
 * <ejs-toolbar></ejs-toolbar>
 * ```
 */
@ComponentMixins([ComponentBase])
export class ToolbarComponent extends Toolbar implements IComponentBase {
public containerContext : any;
public tagObjects: any;
	beforeCreate: any;
	clicked: any;
	created: any;
public destroyed: any;
public childItems: QueryList<ItemsDirective>;
public tags: string[] = ['items'];
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.containerContext  = new ComponentBase();
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.containerContext.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.containerContext.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.containerContext.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        this.tagObjects[0].instance = this.childItems;
        this.containerContext.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-toolbar',
    inputs: inputs,
    outputs: outputs,
    template: `<ng-content select='div'></ng-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {
        childItems: new ContentChild(ItemsDirective)
    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
}

function ToolbarComponent_tsickle_Closure_declarations() {
/** @type {?} */
ToolbarComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ToolbarComponent.ctorParameters;
/** @type {?} */
ToolbarComponent.prototype.containerContext;
/** @type {?} */
ToolbarComponent.prototype.tagObjects;
/** @type {?} */
ToolbarComponent.prototype.beforeCreate;
/** @type {?} */
ToolbarComponent.prototype.clicked;
/** @type {?} */
ToolbarComponent.prototype.created;
/** @type {?} */
ToolbarComponent.prototype.destroyed;
/** @type {?} */
ToolbarComponent.prototype.childItems;
/** @type {?} */
ToolbarComponent.prototype.tags;
/** @type {?} */
ToolbarComponent.prototype.registerEvents;
/** @type {?} */
ToolbarComponent.prototype.addTwoWay;
/** @type {?} */
ToolbarComponent.prototype.ngEle;
/** @type {?} */
ToolbarComponent.prototype.srenderer;
/** @type {?} */
ToolbarComponent.prototype.viewContainerRef;
/** @type {?} */
ToolbarComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { Component, ElementRef, ViewContainerRef, ValueProvider, Renderer2, Injector, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, FormBase, setValue } from '@syncfusion/ej2-angular-base';
import { CheckBox } from '@syncfusion/ej2-buttons';



export const /** @type {?} */ inputs: string[] = ['checked','cssClass','disabled','enableHtmlSanitizer','enablePersistence','enableRtl','htmlAttributes','indeterminate','label','labelPosition','locale','name','value'];
export const /** @type {?} */ outputs: string[] = ['focus', 'blur', 'change','created','checkedChange','indeterminateChange'];
export const /** @type {?} */ twoWays: string[] = ['checked', 'indeterminate'];
/**
 * Represents the Angular CheckBox Component.
 * ```html
 * <ejs-checkbox label='Default'></ejs-checkbox>
 * ```
 */
@ComponentMixins([ComponentBase, FormBase])
export class CheckBoxComponent extends CheckBox implements IComponentBase {
public formCompContext : any;
public formContext : any;
public tagObjects: any;
	change: any;
	created: any;
	checkedChange: any;
public indeterminateChange: any;
public focus: any;
public blur: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.formContext  = new FormBase();
        this.formCompContext  = new ComponentBase();
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnChange(registerFunction: (_: any) => void): void {
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnTouched(registerFunction: () => void): void {
    }
/**
 * @param {?} value
 * @return {?}
 */
public writeValue(value: any): void {
    }
/**
 * @param {?} disabled
 * @return {?}
 */
public setDisabledState(disabled: boolean): void {
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.formCompContext.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.formContext.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.formCompContext.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.formCompContext.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-checkbox',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckBoxComponent),
            multi: true
        }
    ],
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
}

function CheckBoxComponent_tsickle_Closure_declarations() {
/** @type {?} */
CheckBoxComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
CheckBoxComponent.ctorParameters;
/** @type {?} */
CheckBoxComponent.prototype.formCompContext;
/** @type {?} */
CheckBoxComponent.prototype.formContext;
/** @type {?} */
CheckBoxComponent.prototype.tagObjects;
/** @type {?} */
CheckBoxComponent.prototype.change;
/** @type {?} */
CheckBoxComponent.prototype.created;
/** @type {?} */
CheckBoxComponent.prototype.checkedChange;
/** @type {?} */
CheckBoxComponent.prototype.indeterminateChange;
/** @type {?} */
CheckBoxComponent.prototype.focus;
/** @type {?} */
CheckBoxComponent.prototype.blur;
/** @type {?} */
CheckBoxComponent.prototype.registerEvents;
/** @type {?} */
CheckBoxComponent.prototype.addTwoWay;
/** @type {?} */
CheckBoxComponent.prototype.ngEle;
/** @type {?} */
CheckBoxComponent.prototype.srenderer;
/** @type {?} */
CheckBoxComponent.prototype.viewContainerRef;
/** @type {?} */
CheckBoxComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

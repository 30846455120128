import { Component, ElementRef, ViewContainerRef, ChangeDetectionStrategy, QueryList, Renderer2, Injector, ValueProvider, ContentChild } from '@angular/core';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, setValue } from '@syncfusion/ej2-angular-base';
import { Carousel } from '@syncfusion/ej2-navigations';
import { Template } from '@syncfusion/ej2-angular-base';
import { CarouselItemsDirective } from './items.directive';

export const /** @type {?} */ inputs: string[] = ['animation','autoPlay','buttonsVisibility','cssClass','dataSource','enablePersistence','enableRtl','enableTouchSwipe','height','htmlAttributes','indicatorsTemplate','interval','itemTemplate','items','locale','loop','nextButtonTemplate','playButtonTemplate','previousButtonTemplate','selectedIndex','showIndicators','showPlayButton','width'];
export const /** @type {?} */ outputs: string[] = ['slideChanged','slideChanging','selectedIndexChange'];
export const /** @type {?} */ twoWays: string[] = ['selectedIndex'];
/**
 * Represents the EJ2 Angular Carousel Component.
 * ```html
 * <ejs-carousel [items]='carouselItems'></ejs-carousel>
 * ```
 */
@ComponentMixins([ComponentBase])
export class CarouselComponent extends Carousel implements IComponentBase {
public context : any;
public tagObjects: any;
	slideChanged: any;
	slideChanging: any;
public selectedIndexChange: any;
public childItems: QueryList<CarouselItemsDirective>;
public tags: string[] = ['items'];
/**
 * Accepts the template for indicator buttons.
 * \@default null
 */
@Template()
    public indicatorsTemplate: any;
/**
 * Accepts the template for next navigation button.
 * \@default null
 */
@Template()
    public nextButtonTemplate: any;
/**
 * Accepts the template for previous navigation button.
 * \@default null
 */
@Template()
    public previousButtonTemplate: any;
/**
 * Accepts the template for play/pause button.
 * \@default null
 */
@Template()
    public playButtonTemplate: any;
/**
 * Specifies the template option for carousel items.
 * \@default null
 */
@Template()
    public itemTemplate: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.context  = new ComponentBase();
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.context.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.context.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.context.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        this.tagObjects[0].instance = this.childItems;
        this.context.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-carousel',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {
        childItems: new ContentChild(CarouselItemsDirective)
    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'indicatorsTemplate': [{ type: ContentChild, args: ['indicatorsTemplate', ] },],
'nextButtonTemplate': [{ type: ContentChild, args: ['nextButtonTemplate', ] },],
'previousButtonTemplate': [{ type: ContentChild, args: ['previousButtonTemplate', ] },],
'playButtonTemplate': [{ type: ContentChild, args: ['playButtonTemplate', ] },],
'itemTemplate': [{ type: ContentChild, args: ['itemTemplate', ] },],
};
}

function CarouselComponent_tsickle_Closure_declarations() {
/** @type {?} */
CarouselComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
CarouselComponent.ctorParameters;
/** @type {?} */
CarouselComponent.propDecorators;
/** @type {?} */
CarouselComponent.prototype.context;
/** @type {?} */
CarouselComponent.prototype.tagObjects;
/** @type {?} */
CarouselComponent.prototype.slideChanged;
/** @type {?} */
CarouselComponent.prototype.slideChanging;
/** @type {?} */
CarouselComponent.prototype.selectedIndexChange;
/** @type {?} */
CarouselComponent.prototype.childItems;
/** @type {?} */
CarouselComponent.prototype.tags;
/**
 * Accepts the template for indicator buttons.
 * \@default null
 * @type {?}
 */
CarouselComponent.prototype.indicatorsTemplate;
/**
 * Accepts the template for next navigation button.
 * \@default null
 * @type {?}
 */
CarouselComponent.prototype.nextButtonTemplate;
/**
 * Accepts the template for previous navigation button.
 * \@default null
 * @type {?}
 */
CarouselComponent.prototype.previousButtonTemplate;
/**
 * Accepts the template for play/pause button.
 * \@default null
 * @type {?}
 */
CarouselComponent.prototype.playButtonTemplate;
/**
 * Specifies the template option for carousel items.
 * \@default null
 * @type {?}
 */
CarouselComponent.prototype.itemTemplate;
/** @type {?} */
CarouselComponent.prototype.registerEvents;
/** @type {?} */
CarouselComponent.prototype.addTwoWay;
/** @type {?} */
CarouselComponent.prototype.ngEle;
/** @type {?} */
CarouselComponent.prototype.srenderer;
/** @type {?} */
CarouselComponent.prototype.viewContainerRef;
/** @type {?} */
CarouselComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileManagerComponent } from './filemanager.component';
import { FileManagerModule } from './filemanager.module';
import {DetailsView, NavigationPane, LargeIconsView, Toolbar, ContextMenu, BreadCrumbBar} from '@syncfusion/ej2-filemanager';


export const /** @type {?} */ DetailsViewService: ValueProvider = { provide: 'FileManagerDetailsView', useValue: DetailsView};
export const /** @type {?} */ NavigationPaneService: ValueProvider = { provide: 'FileManagerNavigationPane', useValue: NavigationPane};
export const /** @type {?} */ LargeIconsViewService: ValueProvider = { provide: 'FileManagerLargeIconsView', useValue: LargeIconsView};
export const /** @type {?} */ ToolbarService: ValueProvider = { provide: 'FileManagerToolbar', useValue: Toolbar};
export const /** @type {?} */ ContextMenuService: ValueProvider = { provide: 'FileManagerContextMenu', useValue: ContextMenu};
export const /** @type {?} */ BreadCrumbBarService: ValueProvider = { provide: 'FileManagerBreadCrumbBar', useValue: BreadCrumbBar};
/**
 * NgModule definition for the FileManager component with providers.
 */
export class FileManagerAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, FileManagerModule],
    exports: [
        FileManagerModule
    ],
    providers:[
        DetailsViewService,
        NavigationPaneService,
        LargeIconsViewService,
        ToolbarService,
        ContextMenuService,
        BreadCrumbBarService
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function FileManagerAllModule_tsickle_Closure_declarations() {
/** @type {?} */
FileManagerAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
FileManagerAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchComponent } from './switch.component';
import { SwitchModule } from './switch.module';
/**
 * NgModule definition for the Switch component with providers.
 */
export class SwitchAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, SwitchModule],
    exports: [
        SwitchModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function SwitchAllModule_tsickle_Closure_declarations() {
/** @type {?} */
SwitchAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
SwitchAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { Directive, ViewContainerRef, ContentChildren, ContentChild } from '@angular/core';
import { ComplexBase, ArrayBase, setValue } from '@syncfusion/ej2-angular-base';
import { Template } from '@syncfusion/ej2-angular-base';


let /** @type {?} */ input: string[] = ['content', 'cssClass', 'disabled', 'expanded', 'header', 'iconCss', 'id', 'visible'];
let /** @type {?} */ outputs: string[] = [];
/**
 * 'e-accordionitem' directive represent a item of the Angular Accordion.
 * It must be contained in a Accordion component(`ejs-accordion`). 
 * ```html
 * <ejs-accordion> 
 *   <e-accordionitems>
 *    <e-accordionitem header='Header1'></e-accordionitem>
 *    <e-accordionitem header='Header2' content='Content2'></e-accordionitem>
 *   </e-accordionitems>
 * </ejs-accordion>
 * ```
 */
export class AccordionItemDirective extends ComplexBase<AccordionItemDirective> {
public directivePropList: any;
/**
 * Defines single/multiple classes (separated by a space) are to be used for Accordion item customization.
 * \@default null
 */
public cssClass: any;
/**
 * Sets true to disable an accordion item.
 * \@default false
 */
public disabled: any;
/**
 * Sets the expand (true) or collapse (false) state of the Accordion item. By default, all the items are in a collapsed state.
 * \@default false
 */
public expanded: any;
/**
 * Defines an icon with the given custom CSS class that is to be rendered before the header text. 
 * Add the css classes to the `iconCss` property and write the css styles to the defined class to set images/icons. 
 * Adding icon is applicable only to the header. 
 * 
 * \@default null
 */
public iconCss: any;
/**
 * Sets unique ID to accordion item.
 * \@default null
 */
public id: any;
/**
 * Sets false to hide an accordion item.
 * \@default true
 */
public visible: any;
/**
 * Sets the header text to be displayed for the Accordion item. 
 * You can set the title of the Accordion item using `header` property. 
 * It also supports to include the title as `HTML element`, `string`, or `query selector`. 
 * 
 * \@default null
 */
@Template()
    public header: any;
/**
 * Sets the text content to be displayed for the Accordion item. 
 * You can set the content of the Accordion item using `content` property. 
 * It also supports to include the title as `HTML element`, `string`, or `query selector`. 
 * 
 * \@default null
 */
@Template()
    public content: any;
/**
 * @param {?} viewContainerRef
 */
constructor(private viewContainerRef:ViewContainerRef) {
        super();
        setValue('currentInstance', this, this.viewContainerRef);
        this.registerEvents(outputs);
        this.directivePropList = input;
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'e-accordionitems>e-accordionitem',
    inputs: input,
    outputs: outputs,    
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ViewContainerRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'header': [{ type: ContentChild, args: ['header', ] },],
'content': [{ type: ContentChild, args: ['content', ] },],
};
}

function AccordionItemDirective_tsickle_Closure_declarations() {
/** @type {?} */
AccordionItemDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
AccordionItemDirective.ctorParameters;
/** @type {?} */
AccordionItemDirective.propDecorators;
/** @type {?} */
AccordionItemDirective.prototype.directivePropList;
/**
 * Defines single/multiple classes (separated by a space) are to be used for Accordion item customization.
 * \@default null
 * @type {?}
 */
AccordionItemDirective.prototype.cssClass;
/**
 * Sets true to disable an accordion item.
 * \@default false
 * @type {?}
 */
AccordionItemDirective.prototype.disabled;
/**
 * Sets the expand (true) or collapse (false) state of the Accordion item. By default, all the items are in a collapsed state.
 * \@default false
 * @type {?}
 */
AccordionItemDirective.prototype.expanded;
/**
 * Defines an icon with the given custom CSS class that is to be rendered before the header text. 
 * Add the css classes to the `iconCss` property and write the css styles to the defined class to set images/icons. 
 * Adding icon is applicable only to the header. 
 * 
 * \@default null
 * @type {?}
 */
AccordionItemDirective.prototype.iconCss;
/**
 * Sets unique ID to accordion item.
 * \@default null
 * @type {?}
 */
AccordionItemDirective.prototype.id;
/**
 * Sets false to hide an accordion item.
 * \@default true
 * @type {?}
 */
AccordionItemDirective.prototype.visible;
/**
 * Sets the header text to be displayed for the Accordion item. 
 * You can set the title of the Accordion item using `header` property. 
 * It also supports to include the title as `HTML element`, `string`, or `query selector`. 
 * 
 * \@default null
 * @type {?}
 */
AccordionItemDirective.prototype.header;
/**
 * Sets the text content to be displayed for the Accordion item. 
 * You can set the content of the Accordion item using `content` property. 
 * It also supports to include the title as `HTML element`, `string`, or `query selector`. 
 * 
 * \@default null
 * @type {?}
 */
AccordionItemDirective.prototype.content;
/** @type {?} */
AccordionItemDirective.prototype.viewContainerRef;
}

/**
 * AccordionItem Array Directive
 */
export class AccordionItemsDirective extends ArrayBase<AccordionItemsDirective> {
constructor() {
        super('items');
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'ejs-accordion>e-accordionitems',
    queries: {
        children: new ContentChildren(AccordionItemDirective)
    },
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function AccordionItemsDirective_tsickle_Closure_declarations() {
/** @type {?} */
AccordionItemsDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
AccordionItemsDirective.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

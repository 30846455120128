import { Component, ElementRef, ViewContainerRef, Renderer2, Injector, ChangeDetectionStrategy, QueryList, ValueProvider, ContentChild } from '@angular/core';
import { ComponentBase, ComponentMixins, IComponentBase, applyMixins, PropertyCollectionInfo, setValue } from '@syncfusion/ej2-angular-base';
import { Tab } from '@syncfusion/ej2-navigations';

import { TabItemsDirective } from './items.directive';

export const /** @type {?} */ inputs: string[] = ['allowDragAndDrop','animation','cssClass','dragArea','enableHtmlSanitizer','enablePersistence','enableRtl','headerPlacement','height','heightAdjustMode','items','loadOn','locale','overflowMode','reorderActiveTab','scrollStep','selectedItem','showCloseButton','width'];
export const /** @type {?} */ outputs: string[] = ['added','adding','created','destroyed','dragged','dragging','onDragStart','removed','removing','selected','selecting'];
export const /** @type {?} */ twoWays: string[] = [''];
/**
 * Represents the Angular Tab Component.
 * ```html
 * <ejs-tab></ejs-tab>
 * ```
 */
@ComponentMixins([ComponentBase])
export class TabComponent extends Tab implements IComponentBase {
public containerContext : any;
public tagObjects: any;
	added: any;
	adding: any;
	created: any;
	destroyed: any;
	dragged: any;
	dragging: any;
	onDragStart: any;
	removed: any;
	removing: any;
	selected: any;
public selecting: any;
public childItems: QueryList<TabItemsDirective>;
public tags: string[] = ['items'];
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.containerContext  = new ComponentBase();
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.containerContext.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.containerContext.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.containerContext.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        this.tagObjects[0].instance = this.childItems;
        this.containerContext.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-tab',
    inputs: inputs,
    outputs: outputs,
    template: `<ng-content select='div'></ng-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {
        childItems: new ContentChild(TabItemsDirective)
    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
}

function TabComponent_tsickle_Closure_declarations() {
/** @type {?} */
TabComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
TabComponent.ctorParameters;
/** @type {?} */
TabComponent.prototype.containerContext;
/** @type {?} */
TabComponent.prototype.tagObjects;
/** @type {?} */
TabComponent.prototype.added;
/** @type {?} */
TabComponent.prototype.adding;
/** @type {?} */
TabComponent.prototype.created;
/** @type {?} */
TabComponent.prototype.destroyed;
/** @type {?} */
TabComponent.prototype.dragged;
/** @type {?} */
TabComponent.prototype.dragging;
/** @type {?} */
TabComponent.prototype.onDragStart;
/** @type {?} */
TabComponent.prototype.removed;
/** @type {?} */
TabComponent.prototype.removing;
/** @type {?} */
TabComponent.prototype.selected;
/** @type {?} */
TabComponent.prototype.selecting;
/** @type {?} */
TabComponent.prototype.childItems;
/** @type {?} */
TabComponent.prototype.tags;
/** @type {?} */
TabComponent.prototype.registerEvents;
/** @type {?} */
TabComponent.prototype.addTwoWay;
/** @type {?} */
TabComponent.prototype.ngEle;
/** @type {?} */
TabComponent.prototype.srenderer;
/** @type {?} */
TabComponent.prototype.viewContainerRef;
/** @type {?} */
TabComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabItemDirective, TabItemsDirective } from './items.directive';
import { TabComponent } from './tab.component';
import { TabModule } from './tab.module';
/**
 * NgModule definition for the Tab component with providers.
 */
export class TabAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, TabModule],
    exports: [
        TabModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function TabAllModule_tsickle_Closure_declarations() {
/** @type {?} */
TabAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
TabAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

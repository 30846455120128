import { Directive, ViewContainerRef, ContentChildren, ContentChild } from '@angular/core';
import { ComplexBase, ArrayBase, setValue } from '@syncfusion/ej2-angular-base';
import { Template } from '@syncfusion/ej2-angular-base';


let /** @type {?} */ input: string[] = ['content', 'cssClass', 'disabled', 'header', 'headerTemplate', 'id', 'visible'];
let /** @type {?} */ outputs: string[] = [];
/**
 * 'e-tabitem' directive represent a item of the Angular Tab.
 * It must be contained in a Tab component(`ejs-tab`). 
 * ```html
 * <ejs-tab>
 *  <e-tabitems>
 *   <e-tabitem [header]='Header 1' [content]='Content 1'></e-tabitem>
 *   <e-tabitem [header]='Header 2' [content]='Content 2'></e-tabitem>
 *  <e-tabitems> 
 * </ejs-tab>
 * ```
 */
export class TabItemDirective extends ComplexBase<TabItemDirective> {
public directivePropList: any;
/**
 * Sets the CSS classes to the Tab item to customize its styles.
 * \@default ''
 */
public cssClass: any;
/**
 * Sets true to disable user interactions of the Tab item.
 * \@default false
 */
public disabled: any;
/**
 * The object used for configuring the Tab item header properties.
 * \@default {}
 */
public header: any;
/**
 * Sets unique ID to Tab item.
 * \@default null
 */
public id: any;
/**
 * Sets false to hide the Tab item.
 * \@default true
 */
public visible: any;
/**
 * Specifies the content of Tab item, that is displayed when concern item header is selected.
 * \@default ''
 */
@Template()
    public content: any;
    
    @Template()
public header_text: any;
/**
 * Specifies the header text of Tab item.
 * \@default null
 */
@Template()
    public headerTemplate: any;
/**
 * @param {?} viewContainerRef
 */
constructor(private viewContainerRef:ViewContainerRef) {
        super();
        setValue('currentInstance', this, this.viewContainerRef);
        this.registerEvents(outputs);
        this.directivePropList = input;
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'e-tabitems>e-tabitem',
    inputs: input,
    outputs: outputs,    
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ViewContainerRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'content': [{ type: ContentChild, args: ['content', ] },],
'header_text': [{ type: ContentChild, args: ['headerText', ] },],
'headerTemplate': [{ type: ContentChild, args: ['headerTemplate', ] },],
};
}

function TabItemDirective_tsickle_Closure_declarations() {
/** @type {?} */
TabItemDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
TabItemDirective.ctorParameters;
/** @type {?} */
TabItemDirective.propDecorators;
/** @type {?} */
TabItemDirective.prototype.directivePropList;
/**
 * Sets the CSS classes to the Tab item to customize its styles.
 * \@default ''
 * @type {?}
 */
TabItemDirective.prototype.cssClass;
/**
 * Sets true to disable user interactions of the Tab item.
 * \@default false
 * @type {?}
 */
TabItemDirective.prototype.disabled;
/**
 * The object used for configuring the Tab item header properties.
 * \@default {}
 * @type {?}
 */
TabItemDirective.prototype.header;
/**
 * Sets unique ID to Tab item.
 * \@default null
 * @type {?}
 */
TabItemDirective.prototype.id;
/**
 * Sets false to hide the Tab item.
 * \@default true
 * @type {?}
 */
TabItemDirective.prototype.visible;
/**
 * Specifies the content of Tab item, that is displayed when concern item header is selected.
 * \@default ''
 * @type {?}
 */
TabItemDirective.prototype.content;
/** @type {?} */
TabItemDirective.prototype.header_text;
/**
 * Specifies the header text of Tab item.
 * \@default null
 * @type {?}
 */
TabItemDirective.prototype.headerTemplate;
/** @type {?} */
TabItemDirective.prototype.viewContainerRef;
}

/**
 * TabItem Array Directive
 */
export class TabItemsDirective extends ArrayBase<TabItemsDirective> {
constructor() {
        super('items');
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'ejs-tab>e-tabitems',
    queries: {
        children: new ContentChildren(TabItemDirective)
    },
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function TabItemsDirective_tsickle_Closure_declarations() {
/** @type {?} */
TabItemsDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
TabItemsDirective.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

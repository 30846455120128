import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemDirective, MenuItemsDirective } from './items.directive';
import { MenuComponent } from './menu.component';
import { MenuModule } from './menu.module';
/**
 * NgModule definition for the Menu component with providers.
 */
export class MenuAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, MenuModule],
    exports: [
        MenuModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function MenuAllModule_tsickle_Closure_declarations() {
/** @type {?} */
MenuAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
MenuAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}

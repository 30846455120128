import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipDirective, ChipsDirective } from './chips.directive';
import { ChipListComponent } from './chiplist.component';
import { ChipListModule } from './chiplist.module';
/**
 * NgModule definition for the ChipList component with providers.
 */
export class ChipListAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, ChipListModule],
    exports: [
        ChipListModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ChipListAllModule_tsickle_Closure_declarations() {
/** @type {?} */
ChipListAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ChipListAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
